/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../assets/fonts/OpenSans/open-sans-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../assets/fonts/OpenSans/open-sans-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Open Sans Bold */
@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../assets/fonts/OpenSans/open-sans-v15-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../assets/fonts/OpenSans/open-sans-v15-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Open Sans Semibold */
@font-face {
  font-family: 'Open Sans Semibold';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
       url('../assets/fonts/OpenSans/open-sans-v15-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../assets/fonts/OpenSans/open-sans-v15-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Open Sans Light */
@font-face {
  font-family: 'Open Sans Light';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../assets/fonts/OpenSans/open-sans-v15-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../assets/fonts/OpenSans/open-sans-v15-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Quicksand */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: local('Quicksand Regular'), local('Quicksand-Regular'),
       url('../assets/fonts/Quicksand/quicksand-v7-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../assets/fonts/Quicksand/quicksand-v7-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Quicksand Medium */
@font-face {
  font-family: 'Quicksand Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Quicksand Medium'), local('Quicksand-Medium'),
       url('../assets/fonts/Quicksand/quicksand-v7-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../assets/fonts/Quicksand/quicksand-v7-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Quicksand Bold */
@font-face {
  font-family: 'Quicksand Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Quicksand Bold'), local('Quicksand-Bold'),
       url('../assets/fonts/Quicksand/quicksand-v7-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../assets/fonts/Quicksand/quicksand-v7-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Cabin Sketch Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Cabin Sketch Bold'), local('Cabin-Sketch-Bold'),
        url('../assets/fonts/cabin-sketch-v11-latin/cabin-sketch-v11-latin-700.woff2') format('woff2'),
        url('../assets/fonts/cabin-sketch-v11-latin/cabin-sketch-v11-latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Gaegu';
  font-style: normal;
  font-weight: 400;
  src: local('Gaegu'), local('Gaegu'),
  url('../assets/fonts/gaegu-v4-latin/gaegu-v4-latin-regular.woff2') format('woff2'),
  url('../assets/fonts/gaegu-v4-latin/gaegu-v4-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: local('Audiowide'), local('Audiowide'),
  url('../assets/fonts/audiowide-v7-latin/audiowide-v7-latin-regular.woff2') format('woff2'),
  url('../assets/fonts/audiowide-v7-latin/audiowide-v7-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Benton Sans Regular';
  font-style: normal;
  font-weight: 400;
  src: local('BentonSans Regular'), local('BentonSans-Regular'),
    url('../assets/fonts/BentonSans/benton-sans-regular.woff2') format('woff2'),
    url('../assets/fonts/BentonSans/benton-sans-regular.woff') format('woff');
}

@font-face {
	font-family: 'Benton Sans Medium';
	src: url('../assets/fonts/BentonSans/benton-sans-medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Benton Sans Bold';
    src: url('../assets/fonts/BentonSans/benton-sans-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
